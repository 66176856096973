import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import BaseTemplate from '@/components/app/pages/BaseTemplate'
import Home from '@/components/app/pages/Home'
import MyExports from '@/components/app/pages/MyExports'
import UserAuthTemplate from '@/components/auth/pages/UserAuthTemplate'
import OTPInputPage from '@/components/auth/pages/OTPInputPage'
import LinkAccountsPage from '@/components/auth/pages/LinkAccountsPage'
import LoginSignupPage from '@/components/auth/pages/LoginSignupPage'
import PasswordResetPage from '@/components/auth/pages/PasswordResetPage'

// register the plugin on vue
import Toasted from 'vue-toasted';

Vue.use(Router)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

const router = new Router({
    mode: 'history',
    routes: [
      {
        path: '*',
        redirect: '/app'
      },
      {
        path: '/auth',
        component: UserAuthTemplate,
        children: [
          {
            path: '',
            redirect: 'login'
          },
          {
            path: 'login',
            name: 'login',
            component: LoginSignupPage
          },
          {
            path: 'signup',
            name: 'signup',
            component: LoginSignupPage
          },
          {
            path: "otp", 
            name:"otppage", 
            component: OTPInputPage,  
            props: true
          },
          {
            path: "linkuseraccounts", 
            name:"linkuseraccounts", 
            component: LinkAccountsPage,  
            props: true
          },
          {
            path: "passwordreset",
            name: "passwordreset",
            component: PasswordResetPage
          }
        ]
      },
      {
        path: '/app',
        component: BaseTemplate,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            redirect: 'home'
          },
          {
            path: 'home',
            name: 'home',
            component: Home
          },
          {
            path: 'myexports',
            name: 'myexports',
            component: MyExports
          }
        ]
      }
    ]
})

Vue.use(Toasted, {router, position:'top-right', duration: 5000, iconPack : 'mdi'})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const currentUser = firebase.auth().currentUser
  
    if (requiresAuth && !currentUser) {
      next('/auth/login')
    } else if (!requiresAuth && currentUser) {
      next('/app')
    } else if (requiresAuth && currentUser) {
      next()
    } else {
      next()
    }
  })
  
  export default router  