<script>
import { Line } from "vue-chartjs";
// import 'chartjs-adapter-moment';

export default {
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>