<template>
  <v-card-text>
    <v-row justify="center" align="center">
      <v-col cols="11">
        <v-row>
          <v-col>
            <h5 align="center">Reset Password</h5>
          </v-col>
        </v-row>
        <template v-if="!emailSent">
          <v-row>
            <v-col>
              <v-form>
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Email"
                  type="text"
                  v-model="email"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col>
              <v-btn
                color="primary"
                outlined
                block
                :loading="showLoader"
                @click="sendLink"
                >Send Password Reset Link
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col>
              <span
                >A password reset link has been sent to your email address.
                Please check you email.</span
              >
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
const fb = require("../../../firebaseConfig.js");
const config = require("../../../config.js");

export default {
  data() {
    return {
      email: "",
      emailSent: false,
      showLoader: false,
      errorMsg: "",
    };
  },
  methods: {
    sendLink() {
      this.showLoader = true;
      var actionCodeSettings = {
        url: config.CONSTANTS.APPLICATION_WEB_URL + "/dashboard",
      };
      fb.auth
        .sendPasswordResetEmail(this.email, actionCodeSettings)
        .then(() => {
          this.showLoader = false;
          this.emailSent = true;
          this.email = "";
        })
        .catch((err) => {
          console.log(err);
          this.showLoader = false;
          // this.errorMsg = err.message
          if (err.code === "auth/invalid-email") {
            this.errorMsg = "Please enter a valid email address.";
            this.showErrorAlert();
          } else if (err.code === "auth/user-not-found") {
            this.emailSent = true;
          } else {
            this.errorMsg = "Something went wrong. Please try again.";
            this.showErrorAlert();
          }
          this.email = "";
        });
    },
    showErrorAlert() {
      this.$toasted.show(this.errorMsg);
      console.debug(this.errorMsg);
    },
  },
};
</script>