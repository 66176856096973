<template>
  <div>
    <v-row justify="center">
      <v-col lg="11" md="11" sm="12">
        <v-row>
          <v-col lg="9" md="12" sm="12">
            <v-text-field
              label="Search Query"
              prepend-inner-icon="mdi-magnify"
              v-model="searchQuery"
              @keydown.enter="initSearch"
              persistent-hint
              :loading="showLoader"
              hint="<a align='right' target='_blank' href='https://developer.twitter.com/en/docs/twitter-api/tweets/search/integrate/build-a-query#list'>Query Guide</a>"
            >
              <template v-slot:message="{ message }">
                <span v-html="message"> </span>
              </template>
            </v-text-field>
          </v-col>
          <v-col lg="3" md="12" sm="12" dflex>
            <v-select
              prepend-inner-icon="mdi-calendar"
              :items="dateRangeOptions"
              label="Date Range"
              v-model="dateRangeSelection"
              @change="initSearch"
              :loading="showLoader"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="11" md="11" sm="12" v-if="showResults">
        <v-row justify="start">
          <v-col cols="auto">
            <v-btn
              small
              :disabled="!downloadTweetsButtonEnabled"
              @click="initDataDownload"
              outlined
              rouded
            >
              Export All Tweets <v-icon right>mdi-download</v-icon>
            </v-btn>
            <span class="ml-2">
              <v-icon small title="Datasets containing less than 25000 tweets can be exported.">mdi-information-outline</v-icon>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="3" md="12" sm="12">
            <SummaryStatCard
              :value="totalTweetsCount"
              label="Tweets"
              :overlay="false"
            ></SummaryStatCard>
          </v-col>
          <v-col lg="3" md="12" sm="12">
            <SummaryStatCard
              :value="authorsCount"
              label="Authors"
              :overlay="showNarrowSearchOverlay"
            ></SummaryStatCard>
          </v-col>
          <v-col lg="3" md="12" sm="12">
            <SummaryStatCard
              :value="totalRetweets"
              label="Retweets"
              :overlay="showNarrowSearchOverlay"
            ></SummaryStatCard>
          </v-col>
          <v-col lg="3" md="12" sm="12">
            <SummaryStatCard
              :value="totalLikes"
              label="Likes"
              :overlay="showNarrowSearchOverlay"
            ></SummaryStatCard>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="2">
              <v-card-text>
                <p class="text-subtitle-1">Tweets Over Time</p>
                <LineChart
                  responsive
                  :chartdata="timeChartData"
                  :options="timeChartOptions"
                ></LineChart>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card elevation="2">
              <v-card-text>
                <p class="text-subtitle-1">Top Authors</p>
                <AuthorsTable
                  :authorsList="authorsList"
                  :searchQuery="lastSearchQuery"
                  @addAuthorToSearchQuery="addAuthorToSearchQuery"
                ></AuthorsTable>
                <v-overlay
                  absolute
                  opacity="0.7"
                  z-index=3
                  :value="showNarrowSearchOverlay"
                >
                  <span class="text-overline">Narrow Your Search</span>
                </v-overlay>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="2">
              <v-card-text>
                <p class="text-subtitle-1">Topics In Tweets</p>
                <TopicsTable
                  :topicsList="topicsList"
                  @addTopicToSearchQuery="addTopicToSearchQuery"
                  :totalTweetsCount="totalTweetsCount"
                ></TopicsTable>
                <v-overlay
                  absolute
                  opacity="0.7"
                  z-index=3
                  :value="showNarrowSearchOverlay"
                >
                  <span class="text-overline">Narrow Your Search</span>
                </v-overlay>
                <!-- <p v-else>Data not available.</p> -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card elevation="2">
              <v-card-text>
                <p class="text-subtitle-1">Tweet Sources</p>
                <PieChart
                  responsive
                  :chartdata="tweetSourcesChartData"
                  :options="tweetSourcesChartOptions"
                ></PieChart>
              </v-card-text>
              <v-overlay
                absolute
                opacity="0.7"
                z-index=3
                :value="showNarrowSearchOverlay">
                  <span class="text-overline">Narrow Your Search</span>
              </v-overlay>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card elevation="2">
              <v-card-text>
                <p class="text-subtitle-1">Tweet Languages</p>
                <PieChart
                  responsive
                  :chartdata="tweetLangChartData"
                  :options="tweetLangChartOptions"
                ></PieChart>
              </v-card-text>
              <v-overlay
                absolute
                opacity="0.7"
                z-index=3
                :value="showNarrowSearchOverlay">
                  <span class="text-overline">Narrow Your Search</span>
              </v-overlay>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="11" md="11" sm="12" v-else-if="showLoader">
        <v-skeleton-loader type="text@3"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LineChart from "../charts/LineChart";
import PieChart from "../charts/PieChart";
import SummaryStatCard from "../atoms/SummaryStatCard";
import AuthorsTable from "../charts/AuthorsTable";
import TopicsTable from "../charts/TopicsTable";

import { mapState } from "vuex";

const fb = require("../../../firebaseConfig.js");
const numAbbreviate = require("number-abbreviate");

export default {
  components: {
    LineChart,
    PieChart,
    SummaryStatCard,
    AuthorsTable,
    TopicsTable,
  },
  data() {
    return {
      searchQuery: "",
      lastSearchQuery: "",
      totalTweetsCount: "NA",
      avgSentimentScore: "NA",
      timeChartData: null,
      timeChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0,
              },
            },
          ],
        },
      },
      tweetSourcesChartData: null,
      tweetLangChartData: null,
      tweetSourcesChartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      tweetLangChartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      authorsCount: "NA",
      totalRetweets: "NA",
      totalLikes: "NA",
      showLoader: false,
      showResults: false,
      authorsList: [],
      topicsList: [],
      dateRangeOptions: [
        {
          text: "Last 1 Hour",
          value: "l1h",
        },
        {
          text: "Last 12 Hours",
          value: "l12h",
        },
        {
          text: "Last 24 Hours",
          value: "l24h",
        },
        {
          text: "Last 7 Days",
          value: "l7d",
        },
      ],
      dateRangeSelection: "l24h",
    };
  },
  computed: {
    downloadTweetsButtonEnabled: function () {
      return (this.totalTweetsCount != "NA" && this.totalTweetsCount < 25000);
    },
    showNarrowSearchOverlay: function () {
      return (this.totalTweetsCount != "NA" && this.totalTweetsCount > 5000);
    },
    ...mapState(["currentUser"]),
  },
  methods: {
    initSearch() {
      this.lastSearchQuery = this.searchQuery;

      if (this.searchQuery === "") {
        return;
      }

      this.showLoader = true;
      this.showResults = false;
      fb.functions
        .httpsCallable("getRecentTweetsStats", { timeout: 300000 })({
          text: this.searchQuery,
          dateRange: this.dateRangeSelection,
        })
        .then((result) => {
          const data = result.data;
          this.totalTweetsCount = data.totalTweetCount;
          // this.avgSentimentScore =
          //   Math.round((data.avgSentimentScore + Number.EPSILON) * 1000) / 1000;
          this.authorsCount = data.authorsCount;
          this._prepareTimeChartData(data);
          this._prepareSourcesChartData(data);
          this._prepareLangChartData(data);
          this.authorsList = data.authorsList;
          this.authorsList.sort((a, b) => {
            return (
              b.value.author.public_metrics.followers_count -
              a.value.author.public_metrics.followers_count
            );
          });
          console.log("Authors List Size: " + this.authorsList.length);
          this.topicsList = data.topicsList;
          this.topicsList.sort((a, b) => {
            return b.value.tweets - a.value.tweets;
          });
          this.totalRetweets = numAbbreviate(data.totalRetweets, 2);
          this.totalLikes = numAbbreviate(data.totalLikes, 2);
          this.showLoader = false;
          this.showResults = true;

          if (this.totalTweetsCount > 5000) {
            this.$toasted.show(
              "Narrow your search to view all stats.",
              {
                position: "bottom-left",
                type: "error"
              }
            );
          }
          
        })
        .catch((err) => {
          console.error(err);
          this.showLoader = false;
          this.timeChartData = null;
          this.tweetSourcesChartData = null;
          this.tweetLangChartData = null;
          this.totalTweetsCount = "NA";
          this.authorsCount = "NA";
          this.totalRetweets = "NA";
          this.totalLikes = "NA";
          this.authorsList = [];
          this.topicsList = [];
          this.lastSearchQuery = null;
        });
    },
    initDataDownload() {
      if (!this.lastSearchQuery) {
        this.$.show(
          "Invalid operation.",
          {
            position: "bottom-left",
            type: "error"
          }
        );
        return;
      }

      fb.db
        .collection("dataexportrequests")
        .add({
          createdAt: fb.firebase.firestore.FieldValue.serverTimestamp(),
          userId: this.currentUser.uid,
          searchQuery: this.lastSearchQuery,
          dateRange: this.dateRangeSelection,
          status: "pending",
        })
        .then(() => {
          this.$toasted.info(
            "Data export initiated. Your file should be ready in a few moments.",
            {
              action: [
                {
                  text: "My Exports",
                  icon: "cloud-download-outline",
                  // router navigation
                  onClick: () => {
                    let routeData = this.$router.resolve({
                      name: "myexports",
                    });
                    window.open(routeData.href, "_blank");
                  },
                },
              ],
              duration: 10000,
              position: "bottom-left"
            }
          );
        });
    },
    _prepareTimeChartData(data) {
      const labels = data.tweetCounts.map((value) => {
        // return value.start;
        let date = new Date(value.start);
        if (data.tweetCountsGranularity === "day") {
          return value.start.substring(0, 10);
        } else {
          return date.getHours() + ":" + date.getMinutes();
          // return value.start.substring()
        }
      });

      const tweetCountsArr = data.tweetCounts.map((value) => {
        return value.tweet_count;
      });

      this.timeChartData = {
        labels: labels,
        datasets: [
          {
            label: "Tweet Volume",
            data: tweetCountsArr,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      };
    },
    _prepareSourcesChartData(data) {
      console.log(JSON.stringify(data.tweetSourcesList))
      let tweetSourcesList = data.tweetSourcesList
      tweetSourcesList.sort((a, b) => {
        return (
          b.value.tweets -
          a.value.tweets
        );
      });

      let labels = tweetSourcesList.map((value) => {
        return value.value.source;
      });

      let tweetCountsArr = tweetSourcesList.map((value) => {
        return value.value.tweets;
      });

      if (labels.length > 15) {
        let othersCount = 0
        for (var i = 14; i < labels.length; i++) {
          othersCount += tweetCountsArr[i]
        }

        labels = labels.slice(0, 14)
        labels.push('Others')

        tweetCountsArr = tweetCountsArr.slice(0, 14)
        tweetCountsArr.push(othersCount)
      }

      this.tweetSourcesChartData = {
        labels: labels,
        datasets: [
          {
            label: "Source App",
            data: tweetCountsArr,
            backgroundColor: [
              '#1ABC9C', '#16A085', '#2ECC71', '#27AE60', '#3498DB', 
              '#2980B9', '#9B59B6', '#8E44AD', '#34495E', '#2C3E50', 
              '#F1C40F', '#F39C12', '#E67E22', '#D35400', '#E74C3C',
            ],
            tension: 0.1,
          },
        ],
      };
    },
    _prepareLangChartData(data) {
      let tweetLangList = data.tweetLangList
      tweetLangList.sort((a, b) => {
        return (
          b.value.tweets -
          a.value.tweets
        );
      });

      let labels = tweetLangList.map((value) => {
        return value.value.lang;
      });

      let tweetCountsArr = tweetLangList.map((value) => {
        return value.value.tweets;
      });

      if (labels.length > 15) {
        let othersCount = 0
        for (var i = 14; i < labels.length; i++) {
          othersCount += tweetCountsArr[i]
        }

        labels = labels.slice(0, 14)
        labels.push('Others')

        tweetCountsArr = tweetCountsArr.slice(0, 14)
        tweetCountsArr.push(othersCount)
      }

      this.tweetLangChartData = {
        labels: labels,
        datasets: [
          {
            label: "Language",
            data: tweetCountsArr,
            backgroundColor: [
              '#1ABC9C', '#16A085', '#2ECC71', '#27AE60', '#3498DB', 
              '#2980B9', '#9B59B6', '#8E44AD', '#34495E', '#2C3E50', 
              '#F1C40F', '#F39C12', '#E67E22', '#D35400', '#E74C3C',
            ],
            tension: 0.1,
          },
        ],
      };
    },
    addAuthorToSearchQuery(author) {
      let routeData = this.$router.resolve({
        name: "home",
        query: {
          searchQuery:
            this.lastSearchQuery + " from:" + author.value.author.username,
          dateRange: this.dateRangeSelection,
        },
      });
      window.open(routeData.href, "_blank");
    },
    addTopicToSearchQuery(topic) {
      if (this.lastSearchQuery.indexOf("entity:") !== -1) {
        this.$toasted.show(
          "Multiple entity filters not supported.",
          {
            position: "bottom-left",
            type: "error"
          }
        );
        return;
      }

      let routeData = this.$router.resolve({
        name: "home",
        query: {
          searchQuery:
            this.lastSearchQuery + ' context:' + topic.value.domain.id + '.' + topic.value.topic.id,
          dateRange: this.dateRangeSelection,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    if (this.$route.query.searchQuery) {
      this.searchQuery = this.$route.query.searchQuery;
      this.dateRangeSelection = this.$route.query.dateRange;
      this.initSearch();
    }
  },
};
</script>

<style lang="css" scoped>
/* .row-pointer >>> tbody tr :hover {
  cursor: pointer;
} */
</style>