<template>
  <v-data-table
    :headers="authorsListHeaders"
    :items="authorsList"
    item-key="name"
  >
    <template v-slot:[`item.value.author.name`]="{ item }" align="center">
      <v-row align="center" >
        <v-col cols="auto" class="pr-0"
          ><v-avatar size="24">
            <img :src="item.value.author.profile_image_url" alt="John" />
          </v-avatar>
        </v-col>
        <v-col>
          <span class="text-body-2">
            {{ item.value.author.name }}
          </span>
          <span class="text--secondary" style="font-size: 0.75rem">
            @{{ item.value.author.username }}
          </span>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-4"
        @click="openAuthorTwitterPage(item)"
        title="View tweets on Twitter"
      >
        mdi-open-in-new
      </v-icon>
      <v-icon small @click="$emit('addAuthorToSearchQuery', item)"
        title="Add author to search query">
        mdi-filter-plus-outline
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["authorsList", "searchQuery"],
  data() {
    return {
      authorsListHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "value.author.name",
        },
        {
          text: "Followers",
          value: "value.author.public_metrics.followers_count",
        },
        { text: "Tweets", value: "value.tweets" },
        { text: "Verified Account", value: "value.author.verified" },
        { text: "", align: "end", value: "actions" },
      ],
    };
  },
  methods: {
    openAuthorTwitterPage(author) {
      // window.open("https://twitter.com/" + author.value.author.username);
      window.open("https://twitter.com/search?q=" + this.searchQuery + " from:" + author.value.author.username);
    }
  },
};
</script>

<style lang="css" scoped>
  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }
</style>