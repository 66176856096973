<template>
  <a class="bmc-button" target="_blank" href="https://www.buymeacoffee.com/highku">
    <link href="https://fonts.googleapis.com/css?family=Cookie" rel="stylesheet">
    <img src="https://www.buymeacoffee.com/assets/img/BMC-btn-logo.svg" alt="Buy highku a coffee">
    <span style="margin-left:5px">Buy me a coffee</span>
  </a>
</template>

<script>
export default {
  name: 'BuyMeACoffee'
}
</script>

<style scoped>
.bmc-button>span {
  font-family:'Cookie', cursive !important;
}
.bmc-button img {
  width: 27px !important;
  margin-bottom: 1px !important;
  box-shadow: none !important;
  order: none !important;
  vertical-align: middle !important;
  }.bmc-button{line-height: 36px !important;
  height:37px !important;
  text-decoration: none !important;
  display:inline-flex !important;
  color:#333333 !important;
  background-color:#FFDD00 !important;
  border-radius: 3px !important;
  border: 1px solid transparent !important;
  padding: 1px 9px !important;
  font-size: 23px !important;
  letter-spacing:0.6px !important;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  margin: 0 auto !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -o-transition: 0.3s all linear !important;
  -webkit-transition: 0.3s all linear !important;
  -moz-transition: 0.3s all linear !important;
  -ms-transition: 0.3s all linear !important;
  transition: 0.3s all linear !important;
}
.bmc-button:hover, .bmc-button:active, .bmc-button:focus {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  text-decoration: none !important;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  opacity: 0.85 !important;
  color:#555555 !important;
}
</style>