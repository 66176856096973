<template>
  <v-card elevation="2">
    <v-card-text>
      <v-row align="center">
        <v-col cols="auto">
          <span class="text-body-1">{{ value }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="text-overline">{{label}}</span>
        </v-col>
      </v-row>
      <v-overlay
        absolute
        opacity="0.7"
        z-index=3
        :value="overlay"
      >
        <span class="text-overline">Narrow Your Search</span>
      </v-overlay>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: ['value', 'label', 'overlay']
}
</script>