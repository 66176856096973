<template>
  <div>
    <v-row>
      <v-col>
        <v-btn color="primary" :loading="performingRequest" block @click="signInWithTwitter">
          <v-icon left>mdi-twitter</v-icon> {{buttonText}}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const fb = require("../../../firebaseConfig.js");

export default {
  props: ['buttonText'],
  data() {
    return {
      performingRequest: false,
    };
  },
  methods: {
    signInWithTwitter() {
      this.performingRequest = true;
      fb.auth
        .signInWithPopup(fb.twitterAuthProvider)
        .then(async (userCredential) => {
          var isNewUser = userCredential.additionalUserInfo.isNewUser
          await fb.db.collection('/twittertokens/').add({
              userId: userCredential.user.uid,
              accessToken: userCredential.credential.accessToken,
              secret: userCredential.credential.secret,
              createdAt: fb.firebase.firestore.FieldValue.serverTimestamp()
          })
          if (isNewUser) {
            console.debug("New user.")
            this.$emit("signupSuccessful", userCredential);
          } else {
            console.debug("Current user.")
            this.$emit("loginSuccessful", userCredential);
          }
        })
        .catch((err) => {
          console.log(err);
          this.performingRequest = false;
          if (err.code === "auth/multi-factor-auth-required") {
            // The user is a multi-factor user. Second factor challenge is required.
            this.$emit("mfaAuthRequired", err.resolver);
          } else if (err.code === "auth/popup-blocked") {
            this.errorMsg =
              "The Google Sign-in popup was blocked by your browser. Please enable popups for this site and try again.";
            this.showErrorAlert();
          } else if (err.code === "auth/user-disabled") {
            this.errorMsg = "Access denied. User account is disabled.";
            this.showErrorAlert();
          } else if (err.code === "auth/invalid-email") {
            this.errorMsg = "Invalid email address. Please try again.";
            this.showErrorAlert();
          } else if (
            err.code === "auth/account-exists-with-different-credential"
          ) {
            console.log("account-exists-with-different-credential");
            fb.auth.fetchSignInMethodsForEmail(err.email).then((methods) => {
              console.log(methods);
              // Step 3.
              // If the user has several sign-in methods,
              // the first method in the list will be the "recommended" method to use.
              if (methods[0] === "password") {
                // Asks the user their password.
                // In real scenario, you should handle this asynchronously.
                this.$emit("accountExistsWithDifferentCredential", err.email, err.credential);
              } else {
                this.errorMsg = "Unknown account credentials found.";
              }
            });
          } else {
            this.errorMsg = err.message;
            this.showErrorAlert();
          }
        });
    },
    showErrorAlert() {
      this.$toasted.show(this.errorMsg);
      console.error(this.errorMsg);
    },
  },
};
</script>