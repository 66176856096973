<template>
  <v-card-text>
    <v-row justify="center" align="center">
      <v-col cols="11">
        <v-row>
          <v-col>
            <h5 align="center">Two Factor Authentication</h5>
          </v-col>
        </v-row>
        <template v-if="showOTPInputForm">
          <v-row>
            <v-col>
              <span align="center"
                >Enter verification code sent to your phone number ending in
                {{ mfaResolver.hints[0].phoneNumber }}.</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-form>
                <v-otp-input
                  v-model="otpInput"
                  :disabled="loading"
                  @finish="onCodeSubmit"
                ></v-otp-input>
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <a @click="resendCode">Resend Code</a>
            </v-col>
          </v-row>
        </template>
        <template>
          <div id="recaptcha" align="center"></div>
        </template>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
const fb = require("../../../firebaseConfig.js");

export default {
  props: ["mfaResolver"],
  components: {},
  data() {
    return {
      errorMsg: "",
      otpInput: "",
      loading: false,
      appVerifier: null,
      showOTPInputForm: false,
      verificationId: null,
    };
  },
  methods: {
    onCodeSubmit() {
      this.loading = true;
      console.log(this.verificationId);
      var cred = fb.firebase.auth.PhoneAuthProvider.credential(
        this.verificationId,
        this.otpInput
      );
      var multiFactorAssertion =
        fb.firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      // Complete enrollment.
      console.log(
        "multiFactorAssertion" + JSON.stringify(multiFactorAssertion)
      );
      this.mfaResolver
        .resolveSignIn(multiFactorAssertion)
        .then((/* response */) => {
          console.log("MFA resolved.");
          this.$store.commit("setCurrentUser", fb.auth.currentUser);
          this.loading = false;
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          console.log("Error in MFA: " + error);
          this.loading = false;
          this.otpInput = "";
          if (error.code === "auth/invalid-verification-code") {
            this.errorMsg = "Invalid verification code. Please try again.";
          } else if (error.code === "auth/missing-verification-code") {
            this.errorMsg =
              "Please enter the verification code sent to your registered phone number.";
          } else if (error.code === "auth/code-expired") {
            this.errorMsg =
              "Code expired. Please request a new verification code.";
          } else if (
            error.code === "auth/invalid-multi-factor-session" ||
            error.code === "auth/missing-multi-factor-session"
          ) {
            this.errorMsg =
              "Session authentication failed. Please log in again.";
          } else {
            this.errorMsg =
              "Something went wrong. Please refresh the page and try again.";
          }
          this.showErrorAlert();
        });
    },
    triggerSendCodeFlow() {
      console.log(this.mfaResolver);
      var appVerifier = new fb.firebase.auth.RecaptchaVerifier("recaptcha");
      var phoneInfoOptions = {
        multiFactorHint: this.mfaResolver.hints[0],
        session: this.mfaResolver.session,
      };
      var phoneAuthProvider = new fb.firebase.auth.PhoneAuthProvider();
      // Send SMS verification code
      console.log("App Verifier: " + appVerifier);
      phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, appVerifier)
        .then((verificationId) => {
          this.verificationId = verificationId;
          appVerifier.clear();
          this.showOTPInputForm = true;
        })
        .catch((err) => {
          if (err.code === "auth/captcha-check-failed") {
            this.errorMsg = "Captcha check failed. Please try again.";
          } else if (err.code === "auth/user-disabled") {
            this.errorMsg = "Access denied. User account is disabled.";
          } else if (err.code === "auth/unverified-email") {
            this.errorMsg =
              "Email address verification pending. Please verify your email address first.";
          } else {
            this.errorMsg = "Something went wrong. Please try again.";
          }
          this.showErrorAlert();
        });
    },
    resendCode() {
      this.otpInput = "";
      this.verificationId = null;
      this.showOTPInputForm = false;
      this.triggerSendCodeFlow();
    },
    showErrorAlert() {
      this.$toasted.show(this.errorMsg);
      console.debug(this.errorMsg);
    },
  },
  mounted() {
    this.triggerSendCodeFlow();
  },
};
</script>