var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"lg":"11","md":"11","sm":"12"}},[_c('v-row',{staticClass:"pt-4"},[_c('v-col',[_c('span',{staticClass:"text-h6"},[_vm._v("My Data Exports")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.exportsListHeaders,"items":_vm.exports,"item-key":"id"},scopedSlots:_vm._u([{key:"item.data.searchQuery",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.searchQuery)+" "),_c('span',[_c('v-icon',{attrs:{"right":"","small":""},on:{"click":function($event){return _vm.copySearchQuery(item)}}},[_vm._v("mdi-content-copy")])],1)]}},{key:"item.data.dateRange",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateRangeDisplayText(item))+" ")]}},{key:"item.data.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.createdAt.toDate().toLocaleString())+" ")]}},{key:"item.data.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.data.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.data.status === 'ready')?_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":"primary","title":"Click to download"},on:{"click":function($event){return _vm.downloadFile(item)}}},[_vm._v(" mdi-tray-arrow-down ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }