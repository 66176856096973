import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics'

// firebase init goes here
const config = {
    apiKey: "AIzaSyDRrclEQ-aADxmzVWz2_F3xEpVXNNJNYTw",
    authDomain: "fir-saas-starter.firebaseapp.com",
    projectId: "firebase-saas-starter",
    storageBucket: "firebase-saas-starter.appspot.com",
    messagingSenderId: "49145465926",
    appId: "1:49145465926:web:a559c51bdcafb2f8990e47",
    measurementId: "G-LC38FEWKJ8"  
}

firebase.initializeApp(config)
const analytics = firebase.analytics()

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.functions()
const storage = firebase.storage()
const currentUser = auth.currentUser
const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider()

if (location.hostname === "localhost") {
  db.useEmulator("localhost", 8080)
  // auth.useEmulator("http://localhost:9099")
  functions.useEmulator("localhost",5001)
  storage.useEmulator("localhost",9199)
}

export {
  firebase,
  db,
  auth,
  currentUser,
  functions,
  storage,
  googleAuthProvider,
  twitterAuthProvider,
  analytics
}
