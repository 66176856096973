<template>
  <v-card-text>
    <v-row justify="center" align="center">
      <v-col cols="11">
        <v-row>
          <v-col>
            <h5 align="center">Link With Existing Account</h5>
            <span class="small"
              >An existing account was found with the same email address. Please
              enter your password to enable Google Sign In for your
              account.</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-form>
              <v-text-field
                label="Password"
                type="password"
                v-model="password"
              ></v-text-field>
            </v-form>
            <div id="recaptcha"></div>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col align="right" class="pt-0">
            <a>Forgot Password?</a>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              outlined
              block
              :loading="showLoader"
              @click="linkAccounts"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
const fb = require("../../../firebaseConfig.js");

export default {
  props: ["email", "credential"],
  data() {
    return {
      password: "",
      showLoader: false,
      errorMsg: "",
    };
  },
  methods: {
    linkAccounts() {
      fb.auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          // Step 4a.
          return user.linkWithCredential(this.credential);
        })
        .then(() => {
          // Google account successfully linked to the existing Firebase user.
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          this.errorMsg = error.message;
          this.showErrorAlert();
        });
    },
    showErrorAlert() {
      this.$toasted.show(this.errorMsg);
      console.error(this.errorMsg);
    },
  },
};
</script>