<template>
  <v-data-table
    :headers="topicsListHeaders"
    :items="topicsList"
    item-key="name"
  >
    <template v-slot:[`item.value.topic.name`]="{ item }" align="center">
      <v-row align="center" >
        <v-col>
          <span class="text-body-2">
            {{ item.value.topic.name }}
          </span>
          <span class="text--secondary" style="font-size: 0.75rem">
            ({{ item.value.domain.name }})
          </span>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.value.tweets`]="{ item }">
      <v-row align="center" >
        <v-col>
          <span class="text-body-2">
            {{ ((item.value.tweets / totalTweetsCount) * 100).toFixed(2) }} %
          </span>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small @click="$emit('addTopicToSearchQuery', item)"
        title="Add topic to search query">
        mdi-filter-plus-outline
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["topicsList", "totalTweetsCount"],
  data() {
    return {
      topicsListHeaders: [
        {
          text: "Topic",
          align: "start",
          sortable: false,
          value: "value.topic.name",
        },
        { text: "Tweets (%)", align: "start", value: "value.tweets" },
        { text: "", align: "end", value: "actions" },
      ],
    };
  },
  methods: {
  },
};
</script>