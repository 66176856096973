<template>
  <div>
    <v-row justify="center">
      <v-col lg="11" md="11" sm="12">
        <v-row class="pt-4">
          <v-col>
            <span class="text-h6">My Data Exports</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="exportsListHeaders"
              :items="exports"
              item-key="id"
              class="elevation-3"
            >
              <template v-slot:[`item.data.searchQuery`]="{ item }">
                  {{ item.data.searchQuery }} <span><v-icon @click="copySearchQuery(item)" right small>mdi-content-copy</v-icon></span>
              </template>
              <template v-slot:[`item.data.dateRange`]="{ item }">
                  {{ getDateRangeDisplayText(item) }}
              </template>
              <template v-slot:[`item.data.createdAt`]="{ item }">
                  {{ item.data.createdAt.toDate().toLocaleString() }}
              </template>
              <template v-slot:[`item.data.status`]="{ item }">
                  <span class="text-capitalize">{{ item.data.status }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="mr-3"
                  color="primary"
                  @click="downloadFile(item)"
                  v-if="item.data.status === 'ready'"
                  title="Click to download"
                >
                  mdi-tray-arrow-down
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

const fb = require("../../../firebaseConfig.js");

export default {
  computed: {
    ...mapState(["currentUser"]),
  },
  data() {
    return {
      exportsListHeaders: [
        {
          text: "Search Query",
          align: "start",
          sortable: false,
          value: "data.searchQuery",
        },
        {
          text: "Date Range",
          value: "data.dateRange"
        },
        {
          text: "Initiated On",
          value: "data.createdAt",
        },
        {
          text: "Status",
          value: "data.status",
        },
        { text: "", value: "actions" },
      ],
      exports: [],
      queryListener: null,
    };
  },
  mounted() {
    this.queryListener = fb.db
      .collection("/dataexportrequests/")
      .where("userId", "==", this.currentUser.uid)
      .orderBy('createdAt', 'desc')
      .onSnapshot((snap) => {
        let exportList = [];
        snap.forEach((record) => {
          exportList.push(
            {
              id: record.id,
              data: record.data()
            });
        });
        this.exports = exportList;
      });
  },
  methods: {
    downloadFile(obj) {
        fb.storage.refFromURL(obj.data.storageLocation).getDownloadURL().then((url) => {
            window.open(url, "_blank")
        })
    },
    copySearchQuery(obj) {
        navigator.clipboard.writeText(obj.data.searchQuery).then(() => {
            this.$toasted.success("Query copied to clipboard.", { duration: 1000 })
        })
    },
    getDateRangeDisplayText(obj) {
      if (obj.data.dateRange === 'l1h') {
        return "Last 1 Hour"
      } else if (obj.data.dateRange === 'l12h') {
        return "Last 12 Hours"
      } else if (obj.data.dateRange === 'l24h') {
        return "Last 24 Hours"
      } else if (obj.data.dateRange === 'l7d') {
        return "Last 7 Days"
      }
      return "NA"
    }
  },
  beforeDestroy() {
    this.queryListener();
  },
};
</script>