<template>
  <v-card-text>
    <!-- <v-row justify="center" align="center">
      <v-col cols="11">
        <SignUpForm
          v-if="pageType === 'signup'"
          @signupSuccessful="signupSuccessful"
        ></SignUpForm>
        <LoginForm
          v-else
          @loginSuccessful="loginSuccessful"
          @mfaAuthRequired="triggerSendCodeFlow"
        >
        </LoginForm>
      </v-col>
    </v-row>
    <HorizontalDividerWithOr></HorizontalDividerWithOr>
    <v-row justify="center" align="center">
      <v-col cols="11">
        <GoogleSignInButton
          :buttonText="googleButtonText"
          @signupSuccessful="signupSuccessful"
          @loginSuccessful="loginSuccessful"
          @mfaAuthRequired="triggerSendCodeFlow"
          @accountExistsWithDifferentCreential="triggerAccountLinkingFlow"
        ></GoogleSignInButton>
      </v-col>
    </v-row> -->
    <v-row justify="center" align="center">
      <v-col cols="11">
        <TwitterSignInButton
          :buttonText="twitterButtonText"
          @signupSuccessful="signupSuccessful"
          @loginSuccessful="loginSuccessful"
          @mfaAuthRequired="triggerSendCodeFlow"
          @accountExistsWithDifferentCreential="triggerAccountLinkingFlow"
        ></TwitterSignInButton>
      </v-col>
    </v-row>
    <!-- <v-row justify="center" align="center">
      <v-col cols="11" align="center">
        <span v-if="pageType === 'signup'"
          >Have an account?
          <router-link :to="{ name: 'login' }">Log In</router-link></span
        >
        <span v-else-if="pageType === 'login'"
          >Don't have an account?
          <router-link :to="{ name: 'signup' }">Sign Up</router-link></span
        >
      </v-col>
    </v-row> -->
  </v-card-text>
</template>

<script>
const config = require("../../../config.js");

// import SignUpForm from "../forms/SignupForm";
// import LoginForm from "../forms/LoginForm";
// import GoogleSignInButton from "../forms/GoogleSignInButton";
import TwitterSignInButton from "../forms/TwitterSignInButton";
// import HorizontalDividerWithOr from "../HorizontalDividerWithOr";

export default {
  components: {
    // LoginForm,
    // SignUpForm,
    // GoogleSignInButton,
    TwitterSignInButton,
    // HorizontalDividerWithOr,
  },
  data() {
    return {
      errorMsg: "",
    };
  },
  computed: {
    pageType: function () {
      return this.$route.name;
    },
    googleButtonText: function () {
      if (this.$route.name === "login") {
        return "Sign in with Google";
      } else {
        return "Sign up with Google";
      }
    },
    twitterButtonText: function () {
      if (this.$route.name === "login") {
        return "Sign in with Twitter";
      } else {
        return "Sign up with Twitter";
      }
    }
  },
  methods: {
    signupSuccessful(userCredential) {
      console.debug("User:" + JSON.stringify(userCredential.user));
      this.$store.commit("setCurrentUser", userCredential.user);
      if (userCredential.user.email && !userCredential.user.emailVerified) {
        var actionCodeSettings = {
          url: config.CONSTANTS.APPLICATION_WEB_URL + "/dashboard",
        };
        userCredential.user
          .sendEmailVerification(actionCodeSettings)
          .then(() => {
            // Email sent.
            this.$router.push("/dashboard");
          })
          .catch(function (error) {
            alert("Error while sending verification email." + error.message);
          });
      } else {
        this.$router.push("/dashboard");
      }
      console.debug("Sign up done.");
    },
    loginSuccessful(userCredential) {
      this.$store.commit("setCurrentUser", userCredential.user);
      this.$router.push("/dashboard");
    },
    triggerSendCodeFlow(mfaResolver) {
      this.$router.push({
        name: "otppage",
        params: {
          mfaResolver: mfaResolver,
        },
      });
    },
    triggerAccountLinkingFlow(email, credential) {
      this.$router.push({
        name: "linkuseraccounts",
        params: {
          email: email,
          credential: credential,
        },
      });
    },
    showErrorAlert() {
      this.$toasted.show(this.errorMsg);
      console.debug(this.errorMsg);
    },
  },
  mounted() {},
};
</script>